<script setup>
//import { computed } from "vue";
import { useRoute } from "vue-router";

import SidenavItem from "./SidenavItem.vue";
import SidenavItemDropdown from "../../views/components/SidenavItemDropdown.vue";
import router from "../../router";
import cookies from "../../assets/js/cookies.js";
import axios from "axios";
import { computed } from "vue";
import { useStore } from "vuex";

const getRoute = (n) => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[n];
};

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

const config_childs = {
  1:{
    to: "/configuracoes/categorias",
    name: "Categorias",
    class: getRoute(2) === 'categorias' ? 'active' : '',
    icon: 'fa-folders'
  },
  2:{
    to: "/configuracoes/contas",
    name: "Contas",
    class: getRoute(2) === 'contas' ? 'active' : '',
    icon: 'fa-university'
  },
  3:{
    to: "/configuracoes/cartoes-de-credito",
    name: "Cartões de Crédito",
    class: getRoute(2) === 'cartoes-de-credito' ? 'active' : '',
    icon: 'fa-credit-card'
  }
}

const isMobile = () => {
  
  // check if mobile device
  const isMobileDevice = window.navigator.userAgent
    .toLowerCase()
    .includes("mobi");

  if (isMobileDevice) {
    return true;
  } else {
    return false
  }

}

const logout = () => {

  let session = cookies.get('user_agive_session');

  axios.get('/helpers/logout?session='+session)
  .then(res => {

    console.log(res);
    
    if(res.data.error){

      alert(res.data);

    }
    else{

      cookies.remove('user_agive_session');

      if(isMobile()){

        if(window.ReactNativeWebView){

          let message = {
            'action': 'logout'
          };

          window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }

      }

      router.push({name: 'Login'});

    }

  })
  .catch(err => {
    console.error(err); 
  })

}
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item d-none d-lg-block">
        <sidenav-item
          to="/inicio"
          :class="getRoute(1) === 'dashboard-default' ? 'active' : ''"
          :navText="'Inicio'"
        >
          <template v-slot:icon>
            <i class="fal fa-home text-sm opacity-10" 
                :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item d-none d-lg-block">
        <sidenav-item
          to="/lancamentos"
          :class="getRoute(1) === 'lancamentos' ? 'active' : ''"
          :navText="'Lançamentos'"
        >
          <template v-slot:icon>
            <i
              class="fal fa-money-bill-wave text-sm opacity-10"
              :class="darkMode ? 'text-white' : 'text-dark'"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item d-none d-lg-block">
        <sidenav-item
          to="/objetivos"
          :class="getRoute(1) === 'objetivos' ? 'active' : ''"
          :navText="'Objetivos'"
        >
          <template v-slot:icon>
            <i
              class="fal fa-bullseye-arrow text-sm opacity-10"
              :class="darkMode ? 'text-white' : 'text-dark'"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item d-none">
        <sidenav-item
          to="/billing"
          :class="getRoute(1) === 'billing' ? 'active' : ''"
          :navText="'Relatórios'"
        >
          <template v-slot:icon>
            <i class="fal fa-file-chart-line text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item d-none">
        <sidenav-item
          to="/virtual-reality"
          :class="getRoute(1) === 'virtual-reality' ? 'active' : ''"
          :navText="'Limite de gastos'"
        >
          <template v-slot:icon>
            <i class="fal fa-bullseye-arrow text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <SidenavItemDropdown
          to="/configuracoes"
          :class="getRoute(1) === 'configuracoes' ? 'active' : ''"
          :navText="'Configurações'"
          :navChilds="config_childs"
          :collapseId="'collapse-config'"
        >
          <template v-slot:icon>
            <i class="fal fa-cogs text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </SidenavItemDropdown>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/minha-conta"
          :class="getRoute(1) === 'minha-conta' ? 'active' : ''"
          :navText="'Minha Conta'"
        >
          <template v-slot:icon>
            <i class="fal fa-user text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/suporte"
          :class="getRoute(1) === 'suporte' ? 'active' : ''"
          :navText="'Suporte'"
        >
          <template v-slot:icon>
            <i class="fal fa-ticket-alt text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
          </template>
        </sidenav-item>
      </li>
     

      <li class="nav-item">
        <a class="nav-link" type="button" @click="logout()">
          <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
            >
              <i class="fal fa-sign-out text-sm opacity-10" :class="darkMode ? 'text-white' : 'text-dark'"></i>
            </div>
            <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">
              Sair
            </span>
        </a>
        
      </li>

      
    </ul>
  </div>

  
</template>
