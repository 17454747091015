<script setup>
import { onBeforeMount, onMounted} from "vue";
import TransactionsRow from "./components/TransactionsRow.vue";
import setTooltip from "../assets/js/tooltip";
import validSession from "../assets/js/valid-session.js";
import { useStore } from "vuex";
import axios from "axios";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { toast } from 'vue3-toastify';
import loadGif from "@/assets/img/loading.gif"
import 'animate.css';
import { useRoute } from "vue-router";
import CategoriesOptions from "./components/CategoriesOptions.vue";
import SkeletonLoad from "./components/SkeletonLoad.vue";
import calculator from "./components/calculator.vue";
import { computed } from "vue";

let selectCategories = {};
let meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

const store = useStore();
const route = useRoute();

const darkMode = computed(() => store.state.darkMode);

const money = {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }

onBeforeMount(() => {

    validSession.isLogged();

    store.state.transaction_value = {
        value: 0
    }

    store.state.accounts = [];
    store.state.cards = [];
    store.state.transations = [];
    store.state.categoriesExpense = {};
    store.state.categoriesRevenue = {};
    store.state.loadingTransactions = true;
    store.state.savingTransaction = false;
    store.state.modalColor = '';

    store.state.selectedYear = new Date().getFullYear();
    store.state.selectedMonth = {
        index: new Date().getMonth(),
        number: new Date().getMonth() + 1,
        display: meses[new Date().getMonth()]
    };

    store.state.validation = {
        descHasError: false,
        descMessageError: '',
    };

    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    store.state.current_date = year+ '-' + (month < 10 ? '0'+month : month) + '-' + (day < 10 ? '0'+ day : day);

    store.state.transation = {
        type: '',
        description: '',
        amount: 0,
        date: store.state.current_date,
        account: '',
        account_name: '',
        account_destiny: '',
        account_destiny_name: '',
        category: '',
        category_name: '',
        category_icon: '',
        category_color: '',
        recurrence_checked: false,
        recurrence: '',
        recurrence_period: 'monthly',
        initial_installment: 1,
        current_installment: 1,
        end_installment: 1,
        payed: false,
        id: '',
        transaction_id: '',
        invoice_id: '',
        card_id: '',
        howUpdate: '',
        amount_paid: 0,
        ammount_unpaid: 0,
        observation: ''
    }

    store.state.balance = 0;
    store.state.loading_balance = true;
    store.state.foreseen_revenue = 0;
    store.state.revenue_done = 0;
    store.state.foreseen = 0;
    store.state.foreseen_expense = 0;
    store.state.expense_done = 0;

    store.state.invoice_id = route.params.id;

    store.state.filter = {
        account: '',
        status: '',
        type: ''
    }

    getTransaction(false);
    getCards();
    getAccounts();
    getExpenseCategories();
    getRevenueCategories();

})

onMounted(() => {
    
    setTooltip();

});

const getTransaction = async (editing , transaction = '') => {

    store.state.loadingTransactions = true;

    let period = store.state.selectedYear + '-' + (store.state.selectedMonth.number < 10 ? '0' + store.state.selectedMonth.number : store.state.selectedMonth.number);
    let account = store.state.filter.account;
    let status = store.state.filter.status;
    let type = store.state.filter.type;

    axios.get('transactions?period='+period+'&account='+account+'&status='+status+'&type='+type)
    .then(res => {
        
        if(res.status == 200){

            store.state.transations = res.data;
            store.state.loadingTransactions = false;

            getBalance();

            if(editing){

                console.log(transaction);

                setTimeout(() => {

                    let element = document.getElementById('transaction'+ transaction);
                    let top = element.offsetTop;
                    console.log(top);

                    const isMobileDevice = window.navigator.userAgent
                        .toLowerCase()
                        .includes("mobi");

                    if (isMobileDevice) {
                        document.getElementById('transactionList').scrollTo(0, (top - 150));
                    }
                    else{

                        document.getElementById('transactionList').scrollTo(0, (top - 90));

                    }


                }, 500);

                
            }

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getAccounts = async () => {

    axios.get('accounts')
    .then(res => {
        
        if(res.status == 200){

            store.state.accounts = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getCards = async () => {

    axios.get('credit-cards')
    .then(res => {
        
        if(res.status == 200){

            store.state.cards = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getExpenseCategories = async () => {

    axios.get('categories?type=despesa')
    .then(res => {
        
        if(res.status == 200){

            store.state.categoriesExpense = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getRevenueCategories = async () => {

    axios.get('categories?type=receita')
    .then(res => {

    if(res.status == 200){

        store.state.categoriesRevenue = res.data;

    }

    })
    .catch(err => {
        console.error(err); 
    })

}

const getBalance = async () => {

    store.state.loading_balance = true

    axios.get('accounts/balance')
    .then(res => {

    if(res.status == 200){

        store.state.balance = res.data[0].balance;
        store.state.foreseen_revenue = 0;
        store.state.revenue_done = 0;
        store.state.foreseen = 0;
        store.state.foreseen_expense = 0;
        store.state.expense_done = 0;

        calc_balance();
        store.state.loading_balance = false;

    }

})
.catch(err => {
    console.error(err); 
})

}

const setType = (type) => {

    if(type == 'despesa'){
        selectCategories = store.state.categoriesExpense;
        store.state.modalColor = '-danger';
    }
    else if(type == 'receita'){
        selectCategories = store.state.categoriesRevenue;
        store.state.modalColor = '-success';
    }
    else{
        store.state.modalColor = '-gray';
        store.state.transation.description = 'Transferência';
        store.state.transation.payed = true;
        store.state.transation.category = 2;
    }

    store.state.transation.type = type;

}

const clearFields = () => {

    store.state.transaction_value.value = 0

    store.state.transation = {
        type: '',
        description: '',
        amount: 0,
        date: store.state.current_date,
        account: '',
        account_name: '',
        account_destiny: '',
        account_destiny_name: '',
        category: '',
        category_name: '',
        category_icon: '',
        category_color: '',
        recurrence_checked: false,
        recurrence: '',
        recurrence_period: 'monthly',
        initial_installment: 1,
        current_installment: 1,
        end_installment: 1,
        payed: false,
        id: '',
        transaction_id: '',
        invoice_id: '',
        card_id: '',
        howUpdate: '',
        amount_paid: 0,
        ammount_unpaid: 0,
        observation: ''
    }
}

const submitTransaction = async () => {

    store.state.savingTransaction = true;

    let data = new FormData();

    store.state.validation.descHasError = false;

    store.state.transation.amount = store.state.transaction_value.value.replace('R$ ', '');

    //console.log(store.state.transation.amount);

    data.append('type', store.state.transation.type);
    data.append('description', store.state.transation.description);
    data.append('amount', store.state.transation.amount);
    data.append('date', store.state.transation.date);
    data.append('account', store.state.transation.account);
    data.append('account_destiny', store.state.transation.account_destiny);
    data.append('category', store.state.transation.category);
    data.append('recurrence', store.state.transation.recurrence);
    data.append('period', store.state.transation.recurrence_period);
    data.append('initial_installment', store.state.transation.initial_installment);
    data.append('end_installment', store.state.transation.end_installment);
    data.append('payed', store.state.transation.payed ? 'Paid' : '');
    data.append('observation', store.state.transation.observation);

    let url = '/transactions';
    let toast_message = 'Lançamento criado com sucesso!';

    if(store.state.transation.id != ''){

        url = '/transactions/update/'+store.state.transation.id;
        toast_message = 'Lançamento atualizado com sucesso!';

        data.append('transaction_id', store.state.transation.transaction_id);
        data.append('howUpdate', store.state.transation.howUpdate);
        data.append('invoice_id', store.state.transation.invoice_id);
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'description'){
                    store.state.validation.descHasError = true;
                    store.state.validation.descMessageError = value[1];
                }
                else if(value[0] == 'account'){
                    store.state.validation.accountError = true;
                    store.state.validation.accountMessageError = value[1];
                }
                else if(value[0] == 'category'){
                    store.state.validation.categorryError = true;
                    store.state.validation.categoryMessageError = value[1];
                }

            })

            store.state.savingTransaction = false;

        }
        else{

            store.state.validation = {
                descHasError: false,
                descMessageError: '',
            };

            closeModal('addTransaction');

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            store.state.savingTransaction = false;

            getTransaction(store.state.transation.id != '' ? true : false, store.state.transation.id);
            getBalance();
            closeModal('payTransaction');
            clearFields();

            store.state.loading_balance = true;

        }

    })
    .catch(err => {
        console.error(err); 

        toast('Não foi possível realizar a operação, tente mais tarde (Erro '+err.response.status+')', {
            autoClose: 1000,
            theme: "auto",
            type: "error",
            transition: "slide"
        });

        store.state.savingTransaction = false;

    })

}

const editTrasaction = (data) => {

    setType(data.type);

    store.state.transaction_value.value = formatMoney(data.amount);

    store.state.transation = {
        type: data.type,
        description: data.description,
        amount: formatMoney(data.amount),
        date: data.date,
        account: data.account_id > 0 ? data.account_id+'-bank': data.card_id+'-card',
        account_name: data.account_name,
        account_destiny: data.account_destiny_id > 0 ? data.account_destiny_id+'-bank': '',
        account_destiny_name: data.account_destiny_name,
        category: data.category_id,
        category_name: data.category_name,
        category_icon: data.category_icon,
        category_color: data.category_color,
        recurrence_checked: false,
        recurrence: data.recurrence,
        recurrence_period: '',
        initial_installment: 1,
        current_installment: data.current_installment,
        end_installment: data.end_installment,
        payed: data.status == 'Unpaid' ? false : true,
        id: data.id,
        invoice_id: data.invoice_id,
        card_id: data.card_id,
        transaction_id: data.transaction_id,
        howUpdate: 'just_this',
        amount_paid: data.amount_paid,
        ammount_unpaid: data.ammount_unpaid,
        observation: data.observation
    }

}

const payTransation = (transaction) => {

    if(transaction.type != 'transferência' && transaction.status == 'Unpaid'){

        setType(transaction.type);

        store.state.transaction_value.value = formatMoney(transaction.amount);

        store.state.transation = {
            type: transaction.type,
            description: transaction.description,
            amount: formatMoney(transaction.amount),
            date: transaction.date,
            account: transaction.account_id > 0 ? transaction.account_id+'-bank': transaction.card_id+'-card',
            account_name: transaction.account_name,
            account_destiny: transaction.account_destiny_id > 0 ? transaction.account_destiny_id+'-bank': '',
            account_destiny_name: transaction.account_destiny_name,
            category: transaction.category_id,
            category_name: transaction.category_name,
            category_icon: transaction.category_icon,
            category_color: transaction.category_color,
            recurrence_checked: false,
            recurrence: transaction.recurrence,
            recurrence_period: '',
            initial_installment: 1,
            current_installment: transaction.current_installment,
            end_installment: transaction.end_installment,
            payed: 'Paid',
            id: transaction.id,
            transaction_id: transaction.transaction_id,
            invoice_id: transaction.invoice_id,
            card_id: transaction.card_id,
            howUpdate: 'just_this',
            amount_paid: transaction.amount_paid,
            ammount_unpaid: transaction.ammount_unpaid,
            observation: transaction.observation
        }

        openModal('payTransaction');
    }
    else{

        let url = '/transactions/pay/'+transaction.id;

        let data = new FormData();

        data.append('status', transaction.status == 'Paid' ? 'Unpaid' : 'Paid');

        axios.post(url, data)
        .then(res => {
            
            if(!res.data.error){

                toast('Lançamento atualizado com sucesso!', {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                getTransaction(true, transaction.id);
                clearFields();

                store.state.loading_balance = true;

            }

        })
        .catch(err => {
            console.error(err); 
        })

    }


}

const deleteTransation = (id, transaction_id, type) => {

    let url = '/transactions/delete/'+id;

    let data = new FormData();

    data.append('howDelete', type);
    data.append('transaction_id', transaction_id);

    axios.post(url, data)
    .then(res => {
        
        if(!res.data.error){

            toast('Lançamento excluido com sucesso!', {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            closeModal('deleteTransaction');
            getTransaction();
            clearFields();

            store.state.loading_balance = true;

        }

    })
    .catch(err => {
        console.error(err); 
    })

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

const openModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.show();

}

const slideDate = (direction) => {

    store.state.loading_balance = true;

    if(direction == 'prev'){

        let prev_month = store.state.selectedMonth.index > 0 ? store.state.selectedMonth.index - 1 : 11;
        let prev_year = store.state.selectedMonth.index > 0 ? store.state.selectedYear : store.state.selectedYear - 1;
        
        store.state.selectedYear = prev_year;
        store.state.selectedMonth = {
            index: prev_month,
            number: prev_month + 1,
            display: meses[prev_month]
        };

        getTransaction();

    }
    else if(direction == 'next'){

        let next_month = store.state.selectedMonth.index < 11 ? store.state.selectedMonth.index + 1 : 0;
        let next_year = store.state.selectedMonth.index < 11 ? store.state.selectedYear : store.state.selectedYear + 1;

        store.state.selectedYear = next_year;
        store.state.selectedMonth = {
            index: next_month,
            number: next_month + 1,
            display: meses[next_month]
        };

        getTransaction();

    }

}

const formatMoney = (v) => {
    let total = parseFloat(v);

    return total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
}

const formatDate = (d) => {
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

const rotate = (id) => {

    let element = document.getElementById(id);

    element.classList.toggle('fa-rotate-90');

}

const calc_balance = () => {

    store.state.transations.forEach((transaction) => {

        if(transaction.type == 'despesa'){

            if(transaction.status == 'Unpaid'){

                store.state.foreseen_expense += parseFloat(transaction.amount);

            }
            else if(transaction.status == 'Paid'){

                store.state.expense_done += parseFloat(transaction.amount);

            }

        }
        else if(transaction.type == 'receita'){

            if(transaction.status == 'Unpaid'){

                store.state.foreseen_revenue += parseFloat(transaction.amount);

            }
            else if(transaction.status == 'Paid'){

                store.state.revenue_done += parseFloat(transaction.amount);

            }

        }

    });

    store.state.foreseen = (parseFloat(store.state.balance) + parseFloat(store.state.foreseen_revenue)) - parseFloat(store.state.foreseen_expense);

}

const clearFilter = () =>{

    store.state.filter = {
        account: '',
        status: '',
        type: ''
    }

    getTransaction(false);

}

const updateValue = (value) => {
    store.state.transaction_value.value = formatMoney(value);
}

</script>
<template>
    <div class="py-4 container-fluid container-transations">
        <div class="row">
            <div class="col-12">
        
                <div class="card">
                    <div class="card-header sticky-top border-bottom">
                        <div class="row align-items-center">
                            <div class="col-7 col-lg-4 order-2 order-lg-1">
                                <h6>{{store.state.transations.length}} Lançamentos</h6>
                            </div>
                            <div class="col-12 col-lg-4 text-center order-1 order-lg-2 mt-1 mb-2 mt-lg-0 mb-lg-0">
                                <div class="row">
                                    <div class="col-2 col-lg-3">
                                        <a class="cursor-pointer" @click="slideDate('prev')">
                                            <i class="fal fa-chevron-left me-0 me-lg-5"></i>
                                        </a>
                                    </div>
                                    <div class="col-8 col-lg-6 mx-auto">
                                        <span>
                                            {{ store.state.selectedMonth.display }} {{ store.state.selectedYear }}
                                        </span>
                                    </div>
                                    <div class="col-2 col-lg-3">
                                        <a class="cursor-pointer" @click="slideDate('next')">
                                            <i class="fal fa-chevron-right ms-0 ms-lg-5"></i>
                                        </a>
                                    </div>
                                </div>
                                
                                <hr class="d-lg-none">
                            </div>
                            <div class="col-5 col-lg-4 order-3">
                                
                                <div class="dropdown float-end">
                                    <a class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end text-center" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a class="dropdown-item" :class="darkMode ? 'darkmode' : ''" data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType('despesa')"><i class="fas fa-minus me-2">                                                
                                            </i>Despesa</a>
                                        </li>
                                        <li><a class="dropdown-item" :class="darkMode ? 'darkmode' : ''" data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType('receita')"><i class="fal fa-plus me-2"></i>Receita</a></li>
                                        <li><a class="dropdown-item" :class="darkMode ? 'darkmode' : ''" data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType('transferência')"><i class="fal fa-exchange-alt me-2"></i>Transferência</a></li>
                                    </ul>
                                </div>
                                <a class="trasation-list-add-button text-dark float-end mt-n1 mb-2 me-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter" v-tooltip="{
                                                content:'Filtrar',
                                                distance: 13
                                            }">
                                    <i class="fal fa-filter"></i>
                                </a>
                                
                            </div>
                            <div class="col-12 collapse order-4 mt-3" id="collapseFilter">
                                <div class="row align-items-center">
                                    <div class="col-12 col-lg-1 mt-2 mb-2 d-inline-flex align-items-center">
                                        <h5 class="mb-n1 mt-n1">Filtros</h5>
                                    </div>
                                    <div class="col-lg-3 mt-2 mb-2 d-inline-flex align-items-center">

                                        <label for="filterAccount" class="me-2 mb-0 ms-0">Conta</label>
                                        <select 
                                            v-if="store.state.accounts.length >= 1"
                                            class="form-select form-select-lg" 
                                            aria-label="Conta" 
                                            v-model="store.state.filter.account"
                                            id="filterAccount"
                                        >
                                                <option value="" selected disabled>Selecione uma opção</option>
                                                <option
                                                    v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id"
                                                >
                                                    {{ account.name }}
                                                </option>

                                        </select>

                                    </div>
                                    <div class="col-lg-3 mt-2 mb-2 d-inline-flex align-items-center">

                                        <label for="filterStatus" class="me-2 mb-0 ms-0">Status</label>
                                        <select 
                                            v-if="store.state.accounts.length >= 1"
                                            class="form-select form-select-lg" 
                                            aria-label="Status" 
                                            v-model="store.state.filter.status"
                                            id="filterStatus"
                                        >
                                                <option value="" selected disabled>Selecione uma opção</option>
                                                <option value="Unpaid">Não Pago</option>
                                                <option value="Paid">Pago</option>

                                        </select>

                                    </div>
                                    <div class="col-lg-3 mt-2 mb-2 d-inline-flex align-items-center">

                                        <label for="filterType" class="me-2 mb-0 ms-0">Tipo</label>
                                        <select 
                                            v-if="store.state.accounts.length >= 1"
                                            class="form-select form-select-lg" 
                                            aria-label="Tipo" 
                                            v-model="store.state.filter.type"
                                            id="filterType"
                                        >
                                                <option value="" selected disabled>Selecione uma opção</option>
                                                <option value="receita">Receita</option>
                                                <option value="despesa">Despesa</option>
                                                <option value="transferencia">Transferência</option>

                                        </select>

                                    </div>
                                    <div class="col-lg-2 mt-2 mb-2 d-flex align-items-center justify-content-center">
                                        <button class="btn btn-success mt-3" @click="getTransaction(false)">Aplicar</button>
                                        <a @click="clearFilter()" class="trasation-list-add-button text-danger float-end ms-3" type="button" v-tooltip="{
                                                        content:'Limpar filtros',
                                                        distance: 13
                                                    }">
                                            <i class="fal fa-eraser"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-0">
                        <div id="transactionList" class="col-12 trasaction-list">
                            <div class="row">

                                <div class="col-12 text-center mt-4 mb-4" v-if="store.state.loading_balance">
                                    <div class="row">

                                        <div class="col-2 col-lg-1 ms-3 text-center">
                                            <SkeletonLoad
                                                :width='"40px"'
                                                :height="'40px'"
                                                :class="'rounded-circle'"
                                            />
                                        </div>
                                        <div class="col-6 col-lg-8">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-lg-6">
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'15px'"
                                                        :class="'mb-1 mb-lg-2'"
                                                    />
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'15px'"
                                                        :class="'mb-1 mb-lg-0'"
                                                    />
                                                </div>
                                                <div class="col-12 col-lg-3 text-lg-center d-none d-lg-block">
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'15px'"
                                                    />
                                                </div>
                                                <div class="col-12 col-lg-3 text-lg-center">
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'15px'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-3 col-lg-2 text-end">
                                            
                                            <div class="col-12 col-lg-3 text-lg-center d-lg-none mt-3">
                                                <SkeletonLoad
                                                    :width="'30px'"
                                                    :height="'20px'"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                                <div class="col-12 text-center mt-4" v-if="store.state.transations.length == 0 && !store.state.loadingTransactions">
                                    <p>Nenhum lançamento cadastrado.</p>
                                </div>

                                <div class="col-12" v-if="!store.state.loading_balance">

                                    <TransactionsRow 
                                        v-for="(transation, index) in store.state.transations" :key="index"
                                        :transaction = transation
                                        :darkmode = darkMode
                                        @edit="editTrasaction"
                                        @pay="payTransation"
                                    />

                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <div class="card-bottom border-top pb-0 border-radius-bottom-end border-radius-bottom-start footer-transaction" :class="darkMode ? 'darkmode' : ''">

                        <div class="col-12 mt-2">

                            <div class="row">
                                <div class="col-12"></div>
                                <div class="col-12">
                                    <div class="row align-items-center">
                                        <div class="col-6 col-lg-8 text-end">
                                            <p class="balance-text">
                                                Saldo Atual
                                                <br>
                                                Saldo Previsto
                                            </p>
                                        </div>
                                        <div class="col-4 col-lg-2 text-end">
                                            <SkeletonLoad
                                                v-if="store.state.loading_balance"
                                                :width="'90px'"
                                                :height="'20px'"
                                                :class="'mt-n4 mb-1 float-end'"
                                            />
                                            <SkeletonLoad
                                                v-if="store.state.loading_balance"
                                                :width="'90px'"
                                                :height="'20px'"
                                                :class="'float-end'"
                                            />
                                            <p v-if="!store.state.loading_balance" class="balance-text">
                                                <b>{{formatMoney(store.state.balance)}}</b>
                                                <br>
                                                {{formatMoney(store.state.foreseen)}}
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <a 
                                                data-bs-toggle="collapse" 
                                                href="#collapseBalance" 
                                                role="button" 
                                                aria-expanded="false" 
                                                aria-controls="collapseBalance"
                                                @click="rotate('balance_chevron')"
                                            >
                                                <i id="balance_chevron" class="fal fa-chevron-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row collapse" id="collapseBalance">

                                        <div class="col-6 col-lg-8 text-end">
                                            <p class="balance-text">
                                                Receita realizada
                                                <br>
                                                Receita prevista
                                                <br>
                                                Despesa realizada
                                                <br>
                                                Despesa prevista
                                            </p>
                                        </div>
                                        <div class="col-4 col-lg-2 text-end">
                                            <p class="balance-text">
                                                <span class="text-success">{{formatMoney(store.state.revenue_done)}}</span>
                                                <br>
                                                {{formatMoney(store.state.foreseen_revenue)}}
                                                <br>
                                                <span class="text-danger">{{formatMoney(store.state.expense_done)}}</span>
                                                <br>
                                                - {{formatMoney(store.state.foreseen_expense)}}
                                            </p>
                                        </div>
                                        <div class="col-2"></div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    
    </div>

    <div class="modal fade" id="addTransaction" tabindex="-1" aria-labelledby="addTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">
                <div class="modal-body rounded" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">
                        <div class="col-10">
                            <b>
                                {{ store.state.transation.id != '' ? 'Editar' : 'Nova' }} {{ store.state.transation.type }}
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-12 col-lg-11 mx-auto mt-3 mb-3">

                            <div class="row">

                                <div class="col-12 mb-3">
                                    <label for="transactionDesc">Descrição</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="transactionDesc"
                                        name="transactionDesc"
                                        v-model="store.state.transation.description"
                                        :class="store.state.validation.descHasError ? 'is-invalid' : ''"
                                    >
                                    <div id="categoryNameFeedback" class="invalid-feedback">
                                        {{ store.state.validation.descMessageError }}
                                    </div>
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionAmounti">
                                        <div class="row">
                                            <div class="col-6">
                                                Valor
                                            </div>
                                            <div class="col-6">
                                                <calculator @updateValue="updateValue" :value="store.state.transaction_value.value"></calculator>
                                            </div>
                                        </div>                                        
                                    </label>
                                    <input 
                                        type="tel" 
                                        class="form-control form-control-lg" 
                                        id="transactionAmount"
                                        name="transactionAmount"
                                        v-model.lazy="store.state.transaction_value.value" 
                                        v-money="money"
                                    >
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionDate">Data</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-lg" 
                                        id="transactionDate"
                                        name="transactionDate"
                                        v-model="store.state.transation.date"
                                    >
                                </div>

                                <div v-if="store.state.transation.type == 'receita' || store.state.transation.type == 'despesa'" class="col-6 mb-3">
                                    <label for="transactionAccount">Conta/Cartão <i class="fal fa-info-circle" v-tooltip="{
                                                content:'Para cadastrar uma nova conta/cartão<br>acesse o menu <b>Configurações > Contas</b> ou<br><b> Configurações > Cartões</b>',
                                                distance: 15,
                                                html: true
                                            }"></i></label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account"
                                        id="transactionAccount"
                                        :class="store.state.validation.accountError ? 'is-invalid' : ''"
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <optgroup label="Contas">
                                                <option
                                                    v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id+'-bank'"
                                                >
                                                    {{ account.name }}
                                                </option>
                                            </optgroup>

                                            <optgroup label="Cartões">
                                                <option
                                                    v-for="(card, index) in store.state.cards" :key="index"
                                                    :value="card.id+'-card'"
                                                >
                                                    {{ card.name }}
                                                </option>
                                            </optgroup>
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.accountMessageError }}
                                    </div>
                                </div>

                                <div v-if="store.state.transation.type == 'transferência'" class="col-6 mb-3">
                                    <label for="transactionAccountSender">Saiu da conta <i class="fal fa-info-circle"></i></label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account"
                                        id="transactionAccountSender"
                                        :class="store.state.validation.accountError ? 'is-invalid' : ''"                                        
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <option
                                                v-for="(account, index) in store.state.accounts" :key="index"
                                                :value="account.id+'-bank'"
                                            >
                                                {{ account.name }}
                                            </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ store.state.validation.accountMessageError }}
                                    </div>
                                </div>

                                <div v-if="store.state.transation.type == 'transferência'" class="col-6 mb-3">
                                    <label for="transactionAccountDestiny">Entrou na conta <i class="fal fa-info-circle" v-tooltip="{
                                                content:'Para cadastrar uma nova conta<br>acesse o menu <b>configurações > Contas</b>',
                                                distance: 15,
                                                html: true
                                            }"></i></label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account_destiny"
                                        id="transactionAccountDestiny"
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <option
                                                v-for="(account, index) in store.state.accounts" :key="index"
                                                :value="account.id+'-bank'"
                                            >
                                                {{ account.name }}
                                            </option>
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.parentMessageError }}
                                    </div>
                                </div>

                                <div v-if="store.state.transation.type == 'receita' || store.state.transation.type == 'despesa'" class="col-6 mb-3">
                                    <label for="categoryName">Categoria <i class="fal fa-info-circle" v-tooltip="{
                                                content:'Para cadastrar uma nova categoria<br>acesse o menu <b>configurações > Categorias</b>',
                                                distance: 15,
                                                html: true
                                            }"></i></label>
                                    <select 
                                        class="form-select form-select-lg selectpicker" 
                                        aria-label="Categoria" 
                                        v-model="store.state.transation.category"
                                        id="parentSelect"
                                        :class="store.state.validation.categorryError ? 'is-invalid' : ''"
                                    >
                                            <option value="" selected disabled>Selecione a categoria</option>
                                            <CategoriesOptions :categories="selectCategories"></CategoriesOptions>
                
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.categoryMessageError }}
                                    </div>
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="transactionObservation">Observações</label>
                                    <textarea id="transactionObservation" rows="4" class="form-control" v-model="store.state.transation.observation" ></textarea>
                                </div>

                                <div v-if="store.state.transation.id == ''" class="col-12 mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="transactionRecurrence" v-model="store.state.transation.recurrence_checked">
                                        <label class="form-check-label" for="transactionRecurrence">Repetir lançamento</label>
                                    </div>
                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.id == ''" class="col-12 mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionRecurrenceType" id="transactionRecurrenceType1" value="Fixed" v-model="store.state.transation.recurrence">
                                        <label class="form-check-label" for="transactionRecurrenceType1">
                                            É uma {{ store.state.transation.type }} fixa
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionRecurrenceType" id="transactionRecurrenceType2" value="Installments" v-model="store.state.transation.recurrence">
                                        <label class="form-check-label" for="transactionRecurrenceType2">
                                            É um {{ store.state.transation.type }} parcelado
                                        </label>
                                    </div>
                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.recurrence == 'Installments' && store.state.transation.id == ''" class="col-6 mb-3">

                                    <label for="transactionInitialI">Parcela inicial</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.initial_installment"
                                    >
                                        <option v-for="n in 360" :key="n"
                                            :value="n"
                                        >
                                            {{n}}
                                        </option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.recurrence == 'Installments' && store.state.transation.id == ''" class="col-6 mb-3">

                                    <label for="transactionInitialI">Quantidade</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.end_installment"
                                    >
                                        <option v-for="n in 360" :key="n"
                                            :value="n"
                                        >
                                            {{n}}
                                        </option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.id == '' && (store.state.transation.recurrence == 'Fixed' || store.state.transation.recurrence == 'Installments')" class="col-12 mb-3">

                                    <label for="transactionPeriod">Periodicidade</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.recurrence_period"
                                    >
                                        <option value="">Selecione uma opção</option>
                                        <option value="day">Diário</option>
                                        <option value="weekley">Semanal</option>
                                        <option value="biweekly">Quinzenal</option>
                                        <option value="monthly">Mensal</option>
                                        <option value="quarterly">Trimestral</option>
                                        <option value="semi-annual">Semestral</option>
                                        <option value="annual">Anual</option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.id != '' && store.state.transation.recurrence != ''" class="col-11 mx-auto mb-3 bg-light rounded">

                                    <p class="text-danger fw-bold mt-2">
                                        Este lançamento se repete em outras datas.
                                    </p>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers1" value="just_this" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers1">
                                            Atualizar apenas este lançamento
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers2" value="this_after" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers2">
                                            Atualizar este e os próximos
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers3" value="all" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers3">
                                            Atualizar todos os lançamentos
                                        </label>
                                    </div>
                                
                                </div>

                                <div class="col-12 mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="transactionPayment" v-model="store.state.transation.payed">
                                        <label class="form-check-label" for="transactionPayment">Pagamento Realizado</label>
                                    </div>
                                </div>

                                <div class="col-12 text-center mt-4 mb-n4">
                                    <button
                                        v-if="!store.state.savingTransaction"
                                        @click="submitTransaction()"
                                        class="btn"
                                        :class="'btn'+store.state.modalColor"
                                    >
                                        Salvar
                                    </button>
                                    <img v-if="store.state.savingTransaction" :src="loadGif" alt="" width="50">
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="detailsTransaction" tabindex="-1" aria-labelledby="detailsTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">

                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">

                    <div class="row align-items-center">
                        <div class="col-4 text-center">
                            <i :class="'fal ' +  store.state.transation.category_icon +' row-transactions-icons fa-3x' " :style="'color:'+store.state.transation.category_color"></i>
                            <br>
                            <b>{{ store.state.transation.description }}</b>
                            <br>
                            <b :class="'text'+store.state.modalColor">{{store.state.transation.type == 'despesa' ? '-': ''}} {{ store.state.transation.amount }}</b>
                        </div>
                        <div class="col-6">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">Data</span>
                                <br>
                                {{ formatDate(store.state.transation.date) }}
                            </p>
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted mt-2">Conta</span>
                                <br>
                                {{ store.state.transation.account_name }}
                            </p>
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">Categoria</span>
                                <br>
                                {{ store.state.transation.category_name }}
                            </p>
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">Observações</span>
                                <br>
                                {{ store.state.transation.observation }}
                            </p>
                        </div>
                        <div class="col-2 text-end align-self-baseline">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>
                        <hr>
                    </div>

                    <div v-if="store.state.transation.recurrence == 'Installments'" class="row text-center align-items-center">
                        <div class="col-4">
                            <p class="fw-bold">
                                Parcela {{store.state.transation.current_installment}}/{{store.state.transation.end_installment}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">{{store.state.transation.type == 'despesa' ? 'Já paguei': 'Já recebi'}}</span>
                                <br>
                                {{ formatMoney(store.state.transation.amount_paid) }}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">{{store.state.transation.type == 'despesa' ? 'Falta pagar': 'Falta receber'}}</span>
                                <br>
                                <span class="text-danger">{{ formatMoney(store.state.transation.ammount_unpaid) }}</span>
                            </p>
                        </div>
                        <hr>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            
                            <a data-bs-toggle="modal" data-bs-target="#deleteTransaction" class="float-end">
                                <i 
                                    class="fal fa-trash text-danger row-transactions-icons-actions cursor-pointer"
                                    v-tooltip="{
                                                content:'Excluir',
                                                distance: 15
                                            }"
                                >
                                </i>
                            </a>
                            <a data-bs-toggle="modal" data-bs-target="#addTransaction" class="me-3 float-end">
                                <i 
                                    class="fal fa-pencil text-black-50 row-transactions-icons-actions cursor-pointer"
                                    v-tooltip="{
                                                content:'Editar',
                                                distance: 15
                                            }"
                                    
                                >
                                </i>
                            </a>
                        </div>
                    </div>

                </div>
            
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteTransaction" tabindex="-1" aria-labelledby="deleteTransactionModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="col-11 mx-auto mt-4 mb-4">
                        <h5 class="mb-4">Você tem certeza que deseja excluir o lançamento <b>"{{ store.state.transation.description }}"</b>?</h5>
                    </div>
                    <div 
                        class="col-11 mx-auto text-center"
                    >

                        <div  v-if="store.state.transation.recurrence != ''" class="d-grid gap-2">
                            <button class="btn btn-light" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'just_this')">Excluir apenas este lançamento</button>
                            <button class="btn btn-danger" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'this_after')">Excluir este e os próximos</button>
                        </div>

                        <a  v-if="store.state.transation.recurrence != ''" @click="closeModal('deleteTransaction')" class="btn btn-link">Cancelar</a>

                        <div  v-if="store.state.transation.recurrence == ''" class="d-grid gap-2">
                            <button class="btn btn-danger" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'just_this')">Sim</button>
                            <button @click="closeModal('deleteTransaction')" class="btn btn-light" type="button">Não</button>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="payTransaction" tabindex="-1" aria-labelledby="payTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">
                <div class="modal-body" :class="darkMode ? 'darkmode' : ''">
                    <div class="row">
                        <div class="col-10">
                            <b>
                                {{store.state.transation.type == 'despesa' ? 'Pagar': 'Receber'}} {{ store.state.transation.type }}
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                    </div>

                    <div class="row align-items-center">
                        <div class="col-4 text-center">
                            <i :class="'fal ' +  store.state.transation.category_icon +' row-transactions-icons fa-3x' " :style="'color:'+store.state.transation.category_color"></i>
                            <br>
                            <b>{{ store.state.transation.description }}</b>
                            <br>
                        </div>
                        <div class="col-6">

                            <div class="row">

                                <div class="col-12 mb-3">
                                    <label for="transactionAmount">Valor</label>
                                    <input 
                                        type="tel" 
                                        class="form-control form-control-lg" 
                                        id="transactionAmount"
                                        name="transactionAmount"
                                        v-model.lazy="store.state.transaction_value.value" 
                                        v-money="money"
                                    >
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="transactionDate">Data</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-lg" 
                                        id="transactionDate"
                                        name="transactionDate"
                                        v-model="store.state.transation.date"
                                    >
                                </div>

                                <div v-if="store.state.transation.type == 'receita' || store.state.transation.type == 'despesa'" class="col-12 mb-3">
                                    <label for="transactionAccount">Conta/Cartão</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account"
                                        id="transactionAccount"
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <optgroup label="Contas">
                                                <option
                                                    v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id+'-bank'"
                                                >
                                                    {{ account.name }}
                                                </option>
                                            </optgroup>

                                            <optgroup label="Cartões">
                                                <option
                                                    v-for="(card, index) in store.state.cards" :key="index"
                                                    :value="card.id+'-card'"
                                                >
                                                    {{ card.name }}
                                                </option>
                                            </optgroup>
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.parentMessageError }}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center mb-2">
                            <button
                                v-if="!store.state.savingTransaction"
                                @click="submitTransaction()"
                                class="btn"
                                :class="'btn'+store.state.modalColor"
                            >
                                {{store.state.transation.type == 'despesa' ? 'Pagar': 'Receber'}}
                            </button>
                            <img v-if="store.state.savingTransaction" :src="loadGif" alt="" width="50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>

.trasation-list-add-button{
    font-size: 18px;
}

.btn-gray{
    background-color: #67748e !important;
    color: #fff !important;
}

.footer-transaction{
    position: sticky;
    bottom: 0px;
    border-radius: 0 0 1rem 1rem;
    background-color: #fff;
}

.footer-transaction.darkmode{
    position: sticky;
    bottom: 0px;
    border-radius: 0 0 1rem 1rem !important;
    background-color: transparent;
}

.dropdown .dropdown-menu:before {
    display: none !important;
}

.modal-body{
    border-radius: 0.75rem !important;
}

.modal-body.darkmode{
    background-color: #18285e !important;
}

.dark-version .dropdown .dropdown-menu{
    background: #18285e !important;
}

.dropdown-item.darkmode{
    color: #fff !important;
}

.dropdown-item.darkmode:hover{
    color: #18285e !important;
}

.trasaction-list{
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 575.98px) {

    .trasation-list-add-button{
            font-size: 22px;
        }

    .balance-text{
        font-size: 14px;
    }

    .footer-transaction{
        bottom: 55px;
    }

    .container-fluid{
        padding-right: 0.8rem !important;
        padding-left: 0.8rem !important;
    }
    .card .card-header{
        padding: 1rem !important;
    }
}

@media (max-height: 700px) and (max-width: 575.98px){

    .trasaction-list{
        max-height: 44vh;
    }

}

@media (min-height: 701px) and (max-height: 750px) and (max-width: 575.98px){

    .trasaction-list{
        max-height: 49vh;
    }

}

@media (min-height: 751px) and (max-height: 900px) and (max-width: 575.98px){

    .trasaction-list{
        max-height: 55vh;
    }

}

@media (min-height: 901px) and (max-height: 920px) and (max-width: 575.98px){

    .trasaction-list{
        max-height: 58vh;
    }

}

@media (min-height: 921px) and (max-height: 950px) and (max-width: 575.98px){

    .trasaction-list{
        max-height: 59vh;
    }

}

</style>