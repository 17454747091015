<script setup>

import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

const minimizeSidebar = () => store.commit("sidebarMinimize");

const getRoute = (n) => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[n];
};

</script>
<template>
    <div class="col-12 fixed-bottom bg-white shadow-lg d-lg-none" :class="getRoute(1) === 'login' || getRoute(1) === 'cadastro' ? 'd-none' : ''">
        <div class="row text-center">
            <div class="col-3">
                <router-link :to="'/inicio'">
                    <div class="col-12 mx-auto mm-item" :class="getRoute(1) === 'inicio' ? 'active' : ''" >
                        <i 
                            class="fa-home" 
                           :class="getRoute(1) === 'inicio' ? 'fas' : 'fal'"
                        ></i>
                        <p class="mb-n1">Inicio</p>
                    </div>
                </router-link>
            </div>
            <div class="col-3">
                <router-link :to="'/lancamentos'">
                    <div class="col-12 mx-auto mm-item" :class="getRoute(1) === 'lancamentos' ? 'active' : ''" to="/lancamentos">
                            <i 
                            class="fa-money-bill-wave opacity-10" 
                            :class="getRoute(1) === 'lancamentos' ? 'fas' : 'fal'"
                        ></i>
                        <p class="mb-n1">Lançamentos</p>
                    </div>
                </router-link>
            </div>
            <div class="col-3">
                <router-link :to="'/objetivos'">
                    <div class="col-12 mx-auto mm-item" :class="getRoute(1) === 'objetivos' ? 'active' : ''">
                            <i 
                            class="fa-bullseye-arrow opacity-10" 
                            :class="getRoute(1) === 'objetivos' ? 'fas' : 'fal'"
                        ></i>
                        <p class="mb-n1">Objetivos</p>
                    </div>
                </router-link>
            </div>
            <div class="col-3">
                <div class="col-12 mx-auto mm-item" @click="minimizeSidebar">
                    <i 
                        class="fal fa-bars opacity-10" 
                        :class="darkMode ? 'text-white' : 'text-dark'"
                    ></i>
                    <p class="mb-n1">Menu</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .mm-item{
        padding-bottom: 12px;
        padding-top: 12px;
        font-weight: 500;
    }

    .mm-item i{
        font-size: 1rem;
    }

    .mm-item p{
        font-weight: 500;
        font-size: 0.9rem;
    }

    .mm-item.active i{
        background-color: #e6e6e6;
        border-radius: 0.5rem;
        color: #344767;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .mm-item.active p{
        font-weight: 800;
        color: #344767;
    }
</style>