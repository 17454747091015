<script setup>
defineProps({
    transaction: {
        type: Object
    }
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}

function formatDate(d){
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

const emit = defineEmits(['edit', 'pay']);

const editTrasaction = (data) => {
    emit('edit', data);
}

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

</script>

<template>
    <div class="col-12 border-bottom">

        <div class="row align-items-center row-transactions"
            @click="editTrasaction(transaction)" 
            data-bs-toggle="modal" 
            data-bs-target="#detailsTransaction" 
            v-tooltip="{
                        content:'Ver detalhes',
                        distance: 15
                    }"
        >

            <div class="col-2 col-lg-1 ms-2 me-2 me-lg-0 text-center" v-tooltip="{
                            content: transaction.category_name,
                            distance: 15
                        }">
                <div class="text-center shadow icon icon-shape align-self-center rounded-circle" :style="'background-color: '+transaction.category_color">
                    <i :class="'fal ' + transaction.category_icon +' row-transactions-icons' " :style="'color:'+getContrastYIQ(transaction.category_color)"></i>
                </div>
            </div>

            
            <div class="col-5 col-lg-7">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <p class="mb-1 text-sm">
                            {{ formatDate(transaction.date) }}
                        </p>
                        <h6 class="mb-0 text-md">{{ transaction.description }} {{ transaction.recurrence == 'Installments' ? '('+transaction.current_installment+'/'+transaction.end_installment+')' : '' }}</h6>
                    </div>
                    <div class="col-12 col-lg-3 text-lg-center d-none d-lg-block">
                        <h6 class="mb-0 text-md">{{ transaction.account_name }}</h6>
                    </div>
                </div>
            </div>
            <div class="col-4 col-lg-3 text-lg-center">
                <h6 
                    class="mb-0 text-md"
                    :class="transaction.type == 'despesa' ? 'text-danger' : transaction.type == 'receita' ? 'text-success' : ''"
                >
                    {{transaction.type == 'despesa' ? '-': ''}} {{ formatMoney(transaction.amount) }}
                </h6>
            </div>

        </div>

    </div>
</template>

<style>
    .row-transactions{
        min-height: 85px;
    }

    .hover-transaction{
        border-bottom: solid 1px #dee2e6;
    }

    .hover-transaction:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-transactions-icons{
        font-size: 25px;
    }

    .row-transactions-icons-actions{
        font-size: 20px;
    }

    .color-paid {
        color: #2dce89 !important;
    }
</style>