<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import axios from "axios";

const showMenu = ref(false);
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

store.state.notifications = [];

onBeforeMount(() => {
  getNotifications();
});

const getNotifications = async () => {

  axios.get('notifications')
  .then(res => {
      
      if(res.status == 200){

          store.state.notifications = res.data;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

};

const readNotifications = async (id) => {

axios.get('notifications/read/'+id)
.then(res => {
    
    if(res.status == 200){

        window.location.href = res.data.redirect;

    }
    
})
.catch(err => {
    console.error(err); 
})

};

const formatDate = (d) => {
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-md-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-md-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          
        </div>
        <ul class="navbar-nav justify-content-end">
          
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
              @blur="closeMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
              <span v-if="store.state.notifications.length > 0" class="badge bg-light text-dark ms-1">{{ store.state.notifications.length }}</span>
            </a>
            <ul
              id="notification"
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 dropdown-menu-notification"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li v-for="(notification) in store.state.notifications" :key="notification.id" class="mb-2">
                <a class="dropdown-item border-radius-md" @click="readNotifications(notification.id)">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i :class="'fal ' +  notification.icon +' row-transactions-icons pe-3' "></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center text-wrap">
                      <h3 class="mb-1 text-sm font-weight-bold">
                        {{notification.title}}
                      </h3>
                      <h6 class="mb-1 text-sm font-weight-normal">
                        {{notification.description}}
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{formatDate(notification.created_at)}}
                      </p>
                    </div>
                  </div>
                </a>
              </li>

              <li v-if="store.state.notifications.length == 0">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  Você não possuí notificações.
                </a>
              </li>
              
            </ul>
          </li>

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center d-none d-md-block">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
.dropdown-menu-notification {
    min-width: 19rem !important;
    max-width: 22rem !important;
}
</style>